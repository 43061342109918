<template>
  <div>
    <van-popup v-model="isshow" :close-on-click-overlay="false">
      <div class="boss">
        <div class="content">
          <div class="header">
            <img src="../assets/img/name.png" alt="" />
          </div>
          <div class="form">
            <!--  -->
            <p class="error" v-if="erroeShow">提示：{{ message }}</p>
            <input
              type="text"
              v-model="name"
              placeholder="请给你的猪崽取个名字吧！"
              class="vanform"
              onkeyup="this.value=this.value.replace(/[, ]/g,'')"
            />
            <div class="btn">
              <div class="confirm" @click="adopt"></div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { named } from "../common/api";
export default {
  props: {
    isshow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "",
      erroeShow: false,
      message: "",
    };
  },
  methods: {
    async adopt() {
      if (this.name == "") {
        this.message = "请填写名字！";
        this.erroeShow = !this.erroeShow;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, 2000);
      } else {
        const data = {
          access_id: this.$store.state.access_id,
          p_id: this.$store.state.p_id,
          nickname: this.name,
        };
        let res = await named(data);
        if (res.data.code != 200) {
          this.message = res.data.message;
          this.erroeShow = !this.erroeShow;
          setTimeout(() => {
            this.erroeShow = !this.erroeShow;
          }, 2000);
          return;
        }
        if (res.data.code == "200") {
          this.closePage();
        }
      }
    },
    close: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.$emit("update", !this.isshow);
        }, 300);
      };
    })(),
    closePage() {
      window.location.href = "uniwebview://nameClose?test=nameClose";
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.boss {
  width: 9rem;
  height: 6rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 8rem;
    height: 5rem;
    background: #fbe5ba;
    border-radius: 0.2rem;
    overflow: hidden;
    .header {
      height: 1.3rem;
      width: 100%;
      background: #d79f6d;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: 0.75rem;
      }
    }
  }
  .form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0.8rem;
    position: relative;
    .error {
      width: 100%;
      text-align: start;
      padding-left: 0.65rem;
      font-size: 0.35rem;
      color: #ff0000;
      position: absolute;
      bottom: 2.8rem;
    }
    .vanform {
      width: 7rem;
      height: 0.95rem;
      border: none;
      background: rgba(#945614, 0.1);
      font-size: 0.4rem;
      padding: 0 0.2rem;
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 0.5rem;
      .cancel {
        width: 2.2rem;
        height: 0.9rem;
        background: url("../assets/img/quxiao.png");
        background-position: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .confirm {
        height: 1.2rem;
        width: 4rem;
        background: url("../assets/img/true.png");
        background-position: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .close {
    position: absolute;
    top: 0.16rem;
    right: 0.23rem;
    img {
      width: 1rem;
      height: auto;
    }
  }
}
.van-dialog {
  width: 6rem;
  height: 4rem;
  /deep/ .van-dialog__message--has-title {
    padding-top: 0.5rem;
  }
}
</style>
