<template>
  <div>
    <van-popup v-model="goldShow" :close-on-click-overlay="false">
      <div class="boss">
        <div class="content">
          <div class="header">
            <img src="../assets/img/title.png" alt="" />
          </div>
          <div class="info">
            <p class="insufficient">金币不足，请先充值！</p>
            <img src="../assets/img/chongzhi.png" @click="gobuy" alt="" />
          </div>
        </div>
        <div class="close" @click="close">
          <img src="../assets/img/close.png" alt="" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    goldShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit("goldUpdate", !this.goldShow);
    },
    gobuy() {
      console.log("充值");
      window.location.href = "uniwebview://recharge?test=recharge";
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.boss {
  width: 9rem;
  height: 6.3rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 8rem;
  height: 5.2rem;
  background: #fbe5ba;
  border-radius: 0.2rem;
  overflow: hidden;
  .header {
    width: 100%;
    height: 1.3rem;
    background: #d79f6d;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 0.75rem;
    }
  }
  .info {
    width: 100%;
    height: 3.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .insufficient {
      font-size: 0.45rem;
      font-weight: 600;
      color: #945614;
    }
    img {
      width: auto;
      height: 1rem;
      margin-top: 0.4rem;
    }
  }
}
.close {
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
  img {
    width: 1rem;
    height: auto;
  }
}
</style>
