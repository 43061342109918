<template>
  <div>
    <van-popup v-model="show" :close-on-click-overlay="false">
      <div class="boss">
        <div class="content">
          <div class="header">
            <img src="../assets/img/confire.png" alt="" />
          </div>
          <div class="text">
            <p class="money">是否花费{{ total }}金币购买？</p>
            <!-- <p class="hint">注：其中{{ money }}金币作为保证金！</p>
            <p class="hint">保证金用于忘记喂食及紧急治疗使用。</p> -->
            <div class="btn">
              <div class="cancel" @click="close"></div>
              <div class="confirm" @click="sure"></div>
            </div>
          </div>
          <div class="close" @click="close">
            <img src="../assets/img/close.png" alt="" />
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="erroeShow" :overlay="false" class="erroeShow">
      <img src="../assets/img/error.png" alt="" />
      <div class="popbox">
        <p class="texts">购买失败</p>
        <p class="textinfo">{{ message }}</p>
      </div>
    </van-popup>
    <van-popup v-model="buySucess" :overlay="false" class="sucessShow">
      <img src="../assets/img/dui.png" alt="" />
      <div class="popbox">
        <p class="texts">购买成功</p>
        <p class="textinfo">恭喜您成功购买了一头猪崽</p>
      </div>
    </van-popup>
    <name :isshow="isshow" @update="guanbi"></name>
    <gold :goldShow="goldShow" @goldUpdate="goldUpdate"></gold>
  </div>
</template>

<script>
import name from "../components/name.vue";
import { adoption } from "../common/api";
import gold from "./gold.vue";
export default {
  components: {
    name,
    gold,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    total: {
      type: String,
      default: "0",
    },
    money: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      isshow: false,
      goldShow: false,
      erroeShow: false,
      buySucess: false, //购买成功
      message: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    guanbi(val) {
      this.isshow = val;
    },
    close() {
      this.$emit("update", !this.show);
    },
    goldUpdate: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.goldShow = !this.goldShow;
        }, 300);
      };
    })(),
    async sure() {
      const data = {
        access_id: this.$store.state.access_id,
        p_id: this.$store.state.p_id,
      };
      let res = await adoption(data);
      if (res.data.code == "80001") {
        //金币不足
        this.close();
        this.goldShow = true;
        return;
      }
      if (res.data.code == "200") {
        this.buySucess = !this.buySucess;
        setTimeout(() => {
          this.buySucess = !this.buySucess;
          this.isshow = !this.isshow;
        }, 2000);
      } else {
        this.message = res.data.message;
        this.erroeShow = !this.erroeShow;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.boss {
  width: 9rem;
  height: 6.3rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 8rem;
  height: 5.2rem;
  background: #fbe5ba;
  border-radius: 0.2rem;
  overflow: hidden;
  .header {
    height: 1.3rem;
    width: 100%;
    background: #d79f6d;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: 0.75rem;
    }
  }
  .text {
    // width: 100%;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // margin-top: 0.5rem;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 0.5rem;
    .money {
      font-size: 0.4rem;
      font-weight: bold;
      color: #945614;
      line-height: 1.7;
    }
    .hint {
      font-size: 0.38rem;
      color: #fe0000;
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 0.3rem;
      .cancel {
        width: 2.2rem;
        height: 0.9rem;
        background: url("../assets/img/quxiao.png");
        background-position: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-left: 0.4rem;
      }
      .confirm {
        width: 2.2rem;
        height: 0.9rem;
        background: url("../assets/img/queding.png");
        background-position: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-right: 0.4rem;
      }
    }
  }
  .close {
    position: absolute;
    top: 0.1rem;
    right: 0.2rem;
    img {
      width: 1rem;
      height: auto;
    }
  }
}
.erroeShow {
  width: 5.5rem;
  height: 5rem;
  background: #fbe5ba;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0.2rem #000000;
  .texts {
    font-size: 0.45rem;
    font-weight: 600;
    color: #945614;
    // margin-bottom: 0.4rem;
  }
  .popbox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0.4rem;
  }
  img {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.6rem;
  }
  .textinfo {
    font-size: 0.39rem;
    font-weight: 400;
    color: #945614;
    text-align: center;
    margin-top: 0.2rem;
  }
}
.sucessShow {
  width: 5.5rem;
  height: 5rem;
  background: #fbe5ba;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0.2rem #000000;
  .texts {
    font-size: 0.45rem;
    font-weight: 600;
    color: #945614;
    // margin-bottom: 0.4rem;
  }
  .popbox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0.4rem;
  }
  img {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.6rem;
  }
  .textinfo {
    font-size: 0.35rem;
    font-weight: 400;
    color: #945614;
    text-align: center;
    margin-top: 0.2rem;
  }
}
</style>
