<template>
  <div class="boss">
    <div class="bgc">
      <div class="content">
        <div class="welfareText" v-html="xieyi"></div>
        <div class="btn">
          <div class="disagree" @click="disagree">我不同意</div>
          <div class="agree" @click="show = !show">同意购买</div>
        </div>
        <up-pop
          :show="show"
          :total="total"
          :money="money"
          @update="close"
          @sure="sure"
        ></up-pop>
      </div>
      <div class="close" @click="pageClose">
        <img src="../../assets/img/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getXieyi } from '../../common/api'
import upPop from '../../components/confirmPopUps.vue'
export default {
  components: {
    upPop,
  },
  data() {
    return {
      show: false, // 确认购买
      price: '', // 价格
      total: '', // 总价
      money: '', // 保证金
      type: '',
      xieyi: '',
    }
  },
  created() {
    this.price = this.$route.query.price
    this.total = this.$route.query.total
    this.money = this.$route.query.money
    this.type = this.$route.query.type
    this.Xieyi()
  },
  methods: {
    async Xieyi() {
      let res = await getXieyi({ type: 7 })
      console.log(res, 'resresres')
      if (res.data.code != 200) return
      this.xieyi = res.data.data.content
    },
    disagree() {
      this.$router.go(-1)
    },
    close: (function (val) {
      let timer = null
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          this.show = val
        }, 300)
      }
    })(),
    sure(val) {
      this.show = val
    },
    pageClose() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.boss {
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#000, 0.1);
  .bgc {
    width: 7.5rem;
    height: 14.5rem;
    background: url('../../assets/img/xieyi.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    margin: 0.4rem;
    position: relative;
  }
  .content {
    width: 6.8rem;
    height: 9rem;
    margin-top: 3.3rem;
    display: flex;
    flex-direction: column;
  }
  .welfareText {
    flex: 0.87;
    overflow: hidden;
    overflow-y: scroll;
  }
  .btn {
    flex: 0.13;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .disagree {
      height: 0.9rem;
      padding: 0 0.5rem;
      color: #945614;
      border: 1px solid #945614;
      border-radius: 2rem;
      line-height: 0.9rem;
      text-align: center;
    }
    .agree {
      width: 3.4rem;
      height: 1.5rem;
      color: #ffffff;
      font-weight: 600;
      background: url('../../assets/img/tongyi.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      line-height: 1.5rem;
      text-align: center;
    }
  }
}
.close {
  position: absolute;
  top: 1.4rem;
  left: 7rem;
  img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}
</style>
